import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import HorizontalRule from "../../components/horizontal-rule"
import Text from "../../components/text"
import VerticalSection from "../../components/vertical-section"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "galerie-kartografie/gal-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "galerie-kartografie/gal-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "galerie-kartografie/gal-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "galerie-kartografie/gal-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "galerie-kartografie/gal-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "galerie-kartografie/gal-6.jpg" }) { ...fluidImage }
    photo7: file(relativePath: { eq: "galerie-kartografie/gal-7.jpg" }) { ...fluidImage }
    photo8: file(relativePath: { eq: "galerie-kartografie/gal-8.jpg" }) { ...fluidImage }
    photo9: file(relativePath: { eq: "galerie-kartografie/gal-9.jpg" }) { ...fluidImage }
    photo10: file(relativePath: { eq: "galerie-kartografie/gal-10.jpg" }) { ...fluidImage }
    photo11: file(relativePath: { eq: "galerie-kartografie/gal-11.jpg" }) { ...fluidImage }
    photo12: file(relativePath: { eq: "galerie-kartografie/gal-12.jpg" }) { ...fluidImage }
    photo13: file(relativePath: { eq: "galerie-kartografie/gal-13.jpg" }) { ...fluidImage }
    photo14: file(relativePath: { eq: "galerie-kartografie/gal-14.jpg" }) { ...fluidImage }
    photo15: file(relativePath: { eq: "galerie-kartografie/gal-15.jpg" }) { ...fluidImage }
    photo17: file(relativePath: { eq: "galerie-kartografie/gal-17.jpg" }) { ...fluidImage }
    photo18: file(relativePath: { eq: "galerie-kartografie/gal-18.jpg" }) { ...fluidImage }
    photo19: file(relativePath: { eq: "galerie-kartografie/gal-19.jpg" }) { ...fluidImage }
  }
`

const GalerieKartografiePage = ({ data }) => (
  <Layout title="Galerie Kartografie">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalSection>
      <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
      <VerticalText>
        <Heading>Galerie Kartografie Praha, 2018</Heading>
        <Text>
          Prostor <strong>Galerie Kartografie Praha</strong> se nachází ve dvou objektech – zděné budovy z roku 1896 a železobetonového skeletu vystavěného roku 1907. Zděná budova postavená dle návrhu stavitele Josefa Blechy je koncipována jako dvoulodní hala, které dominuje zastropení cihelnou valenou klenbou do I nosníku. Prostor byl původně navržen pro pražský reprodukční závod <strong>M. SCHULZ, grafický závod umělecký</strong> založený roku 1862, dnes slouží jako hlavní výstavní prostor galerie. Vedle této budovy byl roku 1907 postaven železobetonový skelet projektovaný architektem a inženýrem <strong>Bruno Bauerem</strong>, který byl autorem 120 patentů v oblasti železobetonových skeletových konstrukcí. Stavbu provedla firma <strong>Pittel & Brausewetter</strong>. V této unikátní budově je umístěna recepce galerie s návaznosti na další tři výstavní prostory. V roce 2018 proběhla rekonstrukce, jejiž cílem bylo poukázat na různorodost těchto prostor, odprezentovat původní stavební prvky a nosné konstrukce, avšak hlavní výstavní prostory zpřehlednit pro potřeby výstav. Celý prostor byl navržen s důrazem na použití low-costových materiálů v jejich přirozených podobách.
        </Text>
      </VerticalText>
    </VerticalSection>
    <HorizontalImage marginTop borderTop borderBottom image={data.photo3.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo4.childImageSharp.fluid} />
    <VerticalImage image={data.photo5.childImageSharp.fluid} />
    <HorizontalImage marginTop borderBottom borderTop image={data.photo6.childImageSharp.fluid} />
    <VerticalSection>
      <VerticalImage borderRight image={data.photo7.childImageSharp.fluid} />
      <VerticalText>
        <Text>
          Hlavní sál galerie byl navržen jako hala pro tiskařské stroje. Po zrušení tiskárny hala ztratila svou funkci a dokonce byla využívána jako sklad. Tím, že hala sloužila tiskařským závodům, se minulé rekonstrukce řešily hlavně utilitárně, veškeré rozvody TZB tak byly přiznané na povrchu bez jakékoliv snahy o estetiku. Zádáním bylo navrhnout tento prostor tak, aby se jej dalo využít jako výstavní prostory, kanceláře či jiné komerční užití. Po obvodu místnosti byly navrženy předstěny které překrývají rozvody TZB a zvyšují tak vystavní plochu. Výška předstěn je horizontálně zarovnána s I nosníky a probíhá v celém obvodu sálu. V místech dveří a oken se vytvořily minimalistické šambrány, které lehce převyšují výšku předstěny a tak vytvaří tak zajímavý rytmus. Horní část předstěny je zakončena lištou umožnující zavešení obrazů, dále je zde umístěn po celém obvodu snadno přístupný elektro žlab. Stropní konstrukce a horní část stěn byla odkryta na cihlu, ocelové konstrukce byly natřeny na bílo. Béžová barva betonové stěrky byla inspirována barvou malty ve stropní konstrukci. Osvětlení bylo řešeno lištovým systémem umístěným po směru kleneb. V zadní části sálu se vchází do chodby vedoucí na dámské a pánské toalety a také do promítacího sálu.
        </Text>
      </VerticalText>
    </VerticalSection>
    <HorizontalImage marginTop borderTop borderBottom image={data.photo8.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo9.childImageSharp.fluid} />
    <VerticalImage image={data.photo10.childImageSharp.fluid} />
    <HorizontalImage marginTop borderTop borderBottom image={data.photo11.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo12.childImageSharp.fluid} />
    <VerticalImage image={data.photo13.childImageSharp.fluid} />
    <HorizontalRule />
    <VerticalSection>
      <VerticalImage borderRight borderBottom image={data.photo14.childImageSharp.fluid} />
      <VerticalText>
        <Text>
          Větší proměnou prošla část galerie v budově z roku 1907. V této části domu se měnili nájemnící a tím i rozdílné požadavky na prostor, což vedlo k neustálým změnám dispozice. Tyto změny vedly k vrstvení nepůvodních příček, podhledů, elektro rozvodů, a prostupů stěnami. Zásahy jsou dobře patrné na příčce v menším sále, která  zůstala zámerně neomítnuta. Na ní lze například sledovat jak časem probíhaly změny umístění a velikosti dveří. Mou snahou bylo odstranit tyto nové vrstvy a zachovat pouze železobetonový sklelet včetně původních stěn. Ve vstupní chodbě se schodištěm byl odstraněn podhled, začištěny rozvody elektro a obnoveno ateliérové prosklení do místnosti nad průjezdem. Recepce vznikla spojením dvou prostor. Původní příčka byla odstraněna, posunutá blíže oknu a nahrazena profilitovou stěnou. Za touto stěnou se nachází kancelář kurátora. Při rekonstrukci tohoto prostoru byla odkryta původní exteriérová fasáda sousedního domu. Tu jsem se rozhodl zachovat spolu s obnaženým železobetonovým podhledem litým do dřevěného bednění. Recepce je nově propojena koridorem s kuchyní, která dále navazuje na nížší prostor historicky vzniklý přepažením průjezdu do dvoru. V této místnosti byly obnoveny původní štukatérské prvky zdobící průjezd. Malý výstavní sál vznikl vybouráním příček, přístavků a odstraněním podhledů. V tomto sále je zachován v surové podobě železobetonový trámový strop s průvlakem a sloupy. Všechny otvory vedoucí z recepce byly výškově zarovnány, opatřeny nadsvětlíkem, aby bylo možné pozorovat různorodost stropních konstrukcí. Recepční pult je vyroben z broušených cetris desek, použitá svítidla byla nalezena v suterénu budovy. Kuchyně byla vyrobena z low-costových materiálů a vodovodních trubek. Fotografie pořízeny při výstavě <strong>Tichý-Kačer</strong>.
        </Text>
      </VerticalText>
    </VerticalSection>
    <HorizontalImage marginTop borderTop borderBottom image={data.photo15.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo17.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo18.childImageSharp.fluid} />
    <HorizontalImage image={data.photo19.childImageSharp.fluid} />
  </Layout>
)

export default GalerieKartografiePage
